import type { CaughtException, PagingDTO } from '../common'

export const PATHS = {
    base: 'chat-boat/chat'
}

// :: Common

export type UserWithId = {
    id: string
}

export type CustomerWithId = {
    id: string
    name: string
}

export type CustomerWithIdAndName = CustomerWithId & {
    name: string
}

export type CustomerWithPhoneAndName = {
    name: string
    phone: string
}

export type CustomerWithIdAndPhoneAndName = { id?: number } & CustomerWithPhoneAndName

export type CompanyWithIdAndAccountAndPhoneAndName = {
    id: number
    account: string
    phone: string
    name: string
}

export type TicketWithId = {
    id: number
    client?: string
    group?: string
    status?: string
    attendant?: boolean
    type?: string
    companyId?: number
}

// :: Contacts

export type Ticket = {
    client: string
    id: number
    status: string
    group: string
    type: string
    companyId: number
    attendant: boolean
}

type TicketItem = {
    id: number
    group: string
    type: string
    status: string
}

export type ChatContact = {
    id: string
    account: string
    channel: string
    customer: CustomerWithIdAndPhoneAndName
    user: UserWithId
    ticket?: TicketWithId
    tickets?: TicketItem[]
    message?: string
    createdAt: string
    readAt?: string
}

export type ChatContactsRequest = {
    account: string
    tickets: Ticket[]
    userName: string
    channels: string[]
    selectedContactId?: string
    itemsPerPage?: number
    pageIndex?: number
    // :: Helper to better efficiency dealing with two datasets
    numOfUnreadMessages?: number
    numOfReadMessages?: number
    fullScreen?: boolean
}

export type ChatContactsResponse = CaughtException & {
    contacts: ChatContact[]
    selectedContact?: ChatContact
    itemsPerPage: number
    currentPage: number
    numOfUnreadMessages?: number
    numOfReadMessages?: number
}

// :: Tickets

export type ChatTicket = TicketWithId & {
    type: string
    group: string
    status: string
    customer: CustomerWithPhoneAndName
    companyId: number
    company: CompanyWithIdAndAccountAndPhoneAndName
    user: UserWithId
}

export type ChatTicketsRequest = {
    account: string
    tickets: Ticket[]
    userName: string
    customerPhone?: string
    itemsPerPage?: number
    pageIndex?: number
    totalNumOfItems?: number
}

export type ChatTicketsResponse = CaughtException & {
    tickets: ChatTicket[]
    meta: PagingDTO<ChatTicket>['meta']
}

export type PreSaleTicketsRequest = { account: string; username: string; tickets: Ticket[] }

export type PreSaleTicketsResponse = CaughtException & Ticket[]

// :: Message Broker Topics

export const Topics = {
    contacts: 'contacts',
    tickets: 'tickets',
    preSaleTickets: 'preSaleTickets',
    ticketsByUser: 'ticketsByUser'
}

// :: Main Request

export type User = { domain: string; username: string }

export type Request = {
    user?: Record<string, unknown>

    query?: {
        contacts?: ChatContactsRequest
        tickets?: ChatTicketsRequest
        preSaleTickets?: PreSaleTicketsRequest
        ticketsByUser?: { user: User }
    }

    mutation?: {
        // NOOP
    }
}
export type RequestTicketsByUser = {
    domain: string
    username: string
}

export type Response = {
    query?: {
        contacts?: ChatContactsResponse
        tickets?: ChatTicketsResponse
        preSaleTickets?: PreSaleTicketsResponse
        ticketsByUser?: number[]
    }

    mutation?: {
        // NOOP
    }
}

export interface CommunicationAPI {
    send(request: Request): Promise<Response | CaughtException>
}
