import type { CaughtException, PagingDTO, UserContext } from '../common'

export const PATHS = {
    base: 'admin/chats'
}

export enum ChatTicketStatus {
    ALL = 0,
    HAS_TICKET = 1,
    NO_TICKET = 2
}

export enum ChatsGenerateTicketStatus {
    OK = 0,
    TICKET_TYPE_NOT_FOUND = 1,
    SOURCE_NOT_FOUND = 2,
    MEDIA_NOT_FOUND = 3,
    RESPONSABLE_NOT_FOUND = 4,
    COMPANY_NOT_FOUND = 5,
    VEHICLE_NOT_FOUND = 6,
    CUSTOMER_NOT_FOUND = 7,
    PARTNER_NOT_FOUND = 8,
    TICKET_NOT_CREATED = 9
}

export type CustomerIdAndName = {
    id: string
    name: string
}

export type CustomerIdAndNameAndPhone = CustomerIdAndName & {
    phone: string
}

export type ChatContact = {
    account: string
    id: string
    datetime: string
    hasRobot: boolean
    room: string
    customer: CustomerIdAndNameAndPhone
    company: {
        id: number
        phone: string
    }
    user: {
        id: string
    }
    ticket?: {
        id: number
    }
}

export type ChatTicketOption = {
    id: string
    name: string
}

export type ChatSourceOption = {
    id: number
    name: string
}

export type ChatMediaOption = {
    id: number
    name: string
}

export type ChatResponsableOption = {
    id: string
    name: string
}

export type ChatCompanyOption = {
    account: string
    id: number
    name: string
}

// :: ChatsContent

export type ChatsContentRequest = {
    user?: UserContext
    channels: string[]
    startTime: string
    endTime: string
    assureContactId?: string
    ticketStatus?: ChatTicketStatus
    customerIds?: string[]
    itemsPerPage?: number
    pageIndex?: number
    noCache?: boolean
}

export type ChatsContentResponse = {
    contacts: ChatContact[]
    meta: PagingDTO<ChatContact>['meta']
    filters: {
        customers?: CustomerIdAndNameAndPhone[]
    }
}

// :: ChatsCustomerSearch

export type ChatsCustomerSearchRequest = {
    user?: UserContext
    channels: string[]
    startTime: string
    endTime: string
    ticketStatus?: ChatTicketStatus
    text?: string
    itemsPerPage?: number
    pageIndex?: number
}

export type ChatsCustomerSearchResponse = PagingDTO<CustomerIdAndNameAndPhone>

// :: ChatsCompanyOptions

export type ChatsCompanyOptionsRequest = { user?: UserContext; account: string }
export type ChatsCompanyOptionsResponse = { companies: ChatCompanyOption[] }

// :: ChatsTicketOptions

export type ChatsTicketOptionsRequest = { user?: UserContext; account: string; companyId: number }
export type ChatsTicketOptionsResponse = { tickets: ChatTicketOption[] }

// :: ChatsSourceOptions

export type ChatsSourceOptionsRequest = { user?: UserContext; account: string; companyId: number }
export type ChatsSourceOptionsResponse = { sources: ChatSourceOption[] }

// :: ChatsMediaOptions

export type ChatsMediaOptionsRequest = { user?: UserContext; account: string; companyId: number; sourceId: number }
export type ChatsMediaOptionsResponse = { medias: ChatMediaOption[] }

// :: ChatsResponsableOptions

export type ChatsResponsableOptionsRequest = { user?: UserContext; account: string; companyId: number }
export type ChatsResponsableOptionsResponse = { responsables: ChatResponsableOption[] }

// :: ChatsGenerateTicket

export type ChatsGenerateTicketRequest = {
    user?: UserContext
    account: string
    channel: string
    contactId: string
    customerPhone: string
    customerName: string
    companyId: number
    ticketTypeId: string
    sourceId: number
    mediaId: number
    userId?: string
    respectDistribution?: boolean
}

export type ChatsCustomer = {
    id?: number
    customerPhone: string
    customerName: string
}

export type ChatsGenerateTicketsRequest = {
    user?: UserContext
    account: string
    channel: string
    contactId: string
    customers: ChatsCustomer[]
    companyId: number
    eventGroup: string
    ticketTypeId: string
    sourceId: string
    mediaId: string
    userId?: string
    respectDistribution?: boolean
    eventType?: number
}

export type ChatsGenerateTicketResponse = {
    status: ChatsGenerateTicketStatus
    message?: string
    duration: number
    ticketId?: string
}

export type ChatsGenerateTicketsResponse = {
    tickets: {
        status: ChatsGenerateTicketStatus
        message?: string
        ticketId?: string
    }[]
    duration: number
}

// :: Message Broker Topics

export const Topics = {
    content: 'syo-whatsapp-bus-admin-chats-content',
    customerOptions: 'syo-whatsapp-bus-admin-chats-customerOptions',
    companyOptions: 'syo-whatsapp-bus-admin-chats-companyOptions',
    ticketOptions: 'syo-whatsapp-bus-admin-chats-ticketOptions',
    sourceOptions: 'syo-whatsapp-bus-admin-chats-sourceOptions',
    mediaOptions: 'syo-whatsapp-bus-admin-chats-mediaOptions',
    responsableOptions: 'syo-whatsapp-bus-admin-chats-responsableOptions',
    generateTicket: 'syo-whatsapp-bus-admin-chats-generateTicket'
}

// :: Main Request

export type Request = {
    user?: Record<string, unknown>

    query?: {
        content?: ChatsContentRequest
        customerOptions?: ChatsCustomerSearchRequest
        companyOptions?: ChatsCompanyOptionsRequest
        ticketOptions?: ChatsTicketOptionsRequest
        sourceOptions?: ChatsSourceOptionsRequest
        mediaOptions?: ChatsMediaOptionsRequest
        responsableOptions?: ChatsResponsableOptionsRequest
    }

    mutation?: {
        generateTicket?: ChatsGenerateTicketRequest
    }
}

export type Response = {
    query?: {
        content?: ChatsContentResponse
        customerOptions?: ChatsCustomerSearchResponse
        companyOptions?: ChatsCompanyOptionsResponse
        ticketOptions?: ChatsTicketOptionsResponse
        sourceOptions?: ChatsSourceOptionsResponse
        mediaOptions?: ChatsMediaOptionsResponse
        responsableOptions?: ChatsResponsableOptionsResponse
    }

    mutation?: {
        generateTicket?: ChatsGenerateTicketResponse
    }
}

export interface CommunicationAPI {
    send(request: Request): Promise<Response | CaughtException>
}
