export const TEXTS = {
    templates: {
        types: {
            default: 'Padrão',
            interactive: 'Interativo',
            flow: 'Fluxo personalizado'
        },
        interactionTypes: {
            schedule: {
                label: 'Prospecção automática de serviços',
                buttons: {
                    yes: 'Sim',
                    no: 'Não'
                }
            },
            scheduleConfirmation: {
                label: 'Confirmação automática de agendamentos',
                buttons: {
                    yes: 'Sim',
                    no: 'Não',
                    reschedule: 'Reagendar'
                }
            },
            ticketGeneration: {
                label: 'Geração automática de eventos',
                buttons: {
                    yes: 'Sim',
                    no: 'Não'
                }
            },
            updateKm: {
                label: 'Atualização automática de quilometragem',
                buttons: {
                    yes: 'Sim',
                    doNotRemember: 'Não lembro',
                    doNotOwn: 'Não o possuo mais'
                }
            },
            iaInteractiveFlow: {
                label: 'Inteligência artificial (conversas ativas)',
                buttons: {
                    yes: 'Sim',
                    no: 'Não'
                }
            },
            selfSurvey: {
                label: 'Auto-Pesquisa (pesquisa de satisfação)',
                buttons: {
                    yes: 'Sim',
                    no: 'Não'
                }
            },
            userNotification: {
                label: 'Notificação automática de usuários',
                buttons: {
                    accessTicket: 'Acessar o evento'
                }
            },
            ownerNotification: {
                label: 'Notificação automática de gestores',
                buttons: {
                    yes: 'Sim',
                    no: 'Não'
                }
            }
        },
        categories: {
            authentication: { label: 'Autenticação', description: '' },
            marketing: { label: 'Marketing', description: '' },
            utility: { label: 'Utilidade', description: '' }
        },
        supportedLanguages: {
            enUs: 'Inglês (Estados Unidos)',
            es: 'Espanhol',
            ptBr: 'Português (Brasil)'
        },
        fields: {
            CONSORTIUM_CONTRACT_NUMBER: 'Consórcio número do contrato',
            CONSORTIUM_GROUP_ID: 'Consórcio grupo',
            CONSORTIUM_QUOTA_ID: 'Consórcio cota',
            CONSORTIUM_CS_DUE_DATE: 'Vencimento do consórcio',
            CONSORTIUM_CS_BANK_ID: 'Banco do consórcio',
            CONSORTIUM_CS_ASSEMBLY: 'Consórcio assembleia',
            CONSORTIUM_CS_DIGIT: 'Consórcio dígito',
            CONSORTIUM_CS_NO_JURIDICO: 'Consórcio no jurídico',
            CONSORTIUM_CS_NUMBER_DAYS: 'Consórcio quantidade de dias',
            CONSORTIUM_CS_PX_ASSEMBLY: 'Consórcio px assembleia',
            TICKET_ID: 'Evento',
            TICKET_USER: 'Usuário logado',
            TICKET_COMPANY: 'Nome da loja',
            TICKET_COMPANY_ADDRESS: 'Endereço da loja',
            TICKET_COMPANY_PHONE: 'Telefone da loja',
            TICKET_TYPE: 'Tipo evento',
            TICKET_DATE: 'Data do evento',
            TICKET_HOUR: 'Hora do evento',
            TICKET_CURRENT_USER: 'Usuário atual do evento',
            TICKET_CURRENT_USER_FIRST_NAME: 'Primeiro nome do usuário atual do evento',
            TICKET_URL_RESEARCH: 'Link (URL de pesquisa)',
            TICKET_DATE_VISIT: 'Data da visita a loja',
            TICKET_HOUR_VISIT: 'Hora da visita a loja',
            CUSTOMER_NAME: 'Nome do cliente',
            CUSTOMER_FULLNAME: 'Nome completo do cliente',
            VEHICLE_MODEL: 'Modelo de veículo',
            VEHICLE_DS_PRODUCT: 'Modelo do veículo (Descrição)',
            VEHICLE_PLATE: 'Placa de veículo',
            VEHICLE_CHASSI: 'Chassi do veículo',
            VEHICLE_SELLER: 'Vendedor de veículo',
            SERVICE_VEHICLE_MODEL: 'Modelo do veículo (O.S)',
            SERVICE_VEHICLE_PLATE: 'Placa do veículo (O.S)',
            SERVICE_CONSULTANT: 'Consultor do serviço',
            SERVICE_VEHICLE_CHASSI: 'Chassi do veículo (O.S)',
            SERVICE_VEHICLE_DS_MODEL: 'Modelo do veículo O.S (Descrição)',
            NEGOTIATION_BRAND_INTEREST: 'Marca do veículo de interesse',
            NEGOTIATION_MODEL_INTEREST: 'Modelo do veículo de interesse'
        },
        deleteErrorMessages: {
            noTemplatesToDelete: 'Nenhum template para deletar.',
            credentialNotFound: 'Credencial não encontrada para o canal.'
        },
        createErrorMessages: {
            duplicateNameAndLanguage: 'Já existe um modelo com o nome e idioma fornecidos.'
        },
        uploadTemplateImageMessages: {
            uploadError:
                'Ocorre um problema ao fazer upload da imagem para a Gupshup na criação do template! Favor tentar novamente em alguns minutos.'
        }
    }
}
