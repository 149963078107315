export type TechProviderStatus = 'IN_PROGRESS' | 'FAILED' | 'FINISHED'
export type TechProviderSendersDTO = {
    group: string
    channelPhoneId: string
    status?: TechProviderStatus
    type?: string
    channelPhone?: string
    isSalesApproved: boolean
    createdBy: string
    editedBy?: string
    createdAt?: Date
    editedAt?: Date
}
export type SendersRequestDTO = TechProviderSendersRequest & {
    group: string
    isSalesApproved: boolean
    type?: string
    createdBy: string
    editedBy?: string
}

export type TechProviderSendersRequest = {
    channelPhoneId: string
    channelPhone?: string
    status?: TechProviderStatus
}

export type TechProviderInfo = {
    envs: {
        appId: string
        solutionId: string
        configId: string
    }
    senderControl: {
        creationCredits: number
        created: number
    }
    senders: TechProviderSendersDTO[]
}

export type TechProviderInfoRequest = { group: string }

export type TechProviderSenderControlRequest = { group: string; creationCredits: number }

export type TechProviderSenderControlUpdateRequest = { group: string }

export type TechProviderSenderControlDTO = {
    group: string
    creationCredits: number
    createdAt?: Date
    updatedAt?: Date
}

export type TechProviderSenderByGroupInfo = { hired: number; inUse: number }

export type TechProviderSendersForSyonetAdmin = Map<string, TechProviderSenderByGroupInfo>

export type TechProviderSendersForSyonetAdminResponse = { group: string; hired: number; inUse: number }[]

export type TechProviderSyncSendersForSyonetAdminResponse = {
    expired: boolean
    lastSyncDate: Date
    timeToNextSync: string
}

export const Path = {
    save: 'tech-provider-senders/save',
    syncSenders: 'tech-provider-senders/sync-senders',
    toggleIsSalesApproval: 'tech-provider-senders/toggle-is-sales-approval',
    saveSenderControl: 'tech-provider-senders/save-sender-control',
    updateSenderControl: 'tech-provider-senders/update-sender-control',
    syncForSendersSyonetAdmin: 'tech-provider-senders/sync-for-senders-syonet-admin',
    checkSyncSendersDataExpiration: 'tech-provider-senders/check-sync-senders-data-expiration'
}
