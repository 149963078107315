import { ChannelsResponse } from './channel'
import { Extras, DraftExtras, DeleteExtraRequest, GenericStatusType } from './extras'
import { ChannelDraftGeneral } from './general'
import { DeleteMessages, DraftMessages, Messages } from './messages'
import { DeleteTagRequestFields, DraftTags, Tags } from './tag'
import { Configured, Media, Source } from './self-schedule'
import { ChannelDraftMenus } from './menus'
import { Language } from 'src/translation'
import { SelfRescueConfigView } from './self-rescue'
import { DeleteWorkingHoursRequest, DraftWorkingHours, WorkingHours } from './working-hours'
import { ChannelAiConfigDraft } from './ai-config'
import { ChannelDraftFlowBuilder, DraftFlowBuilder, FlowType } from './flow-builder'

export type ChannelKey = keyof ChannelDraftConfig

export type ChannelTabRequest = {
    channel: string
}

export type ChannelTabRequestQueryParam = {
    ignoreCache: string
    companyId?: number
    companyGroupSchedule?: number
    page?: string
    limit?: string
    flowName?: string
}

export type ChannelDraftConfig = {
    tags?: DraftTags
    extras?: DraftExtras
    general?: ChannelDraftGeneral
    messages?: DraftMessages
    selfSchedule?: DraftSelfSchedule
    selfRescue?: DraftSelfRescue
    menus?: ChannelDraftMenus
    workingHours?: DraftWorkingHours
    aiConfig?: ChannelAiConfigDraft
    flowBuilder?: DraftFlowBuilder
}

export type DraftSelfRescue = SelfRescueConfigView
export type DraftSelfSchedule = { save: Configured[]; remove: number[] }
export type ChannelDraftGetRequest = {
    group?: string
    user?: AuthUserInfo
    ignoreCache?: boolean
    companyId?: number
    companyId2Clone?: number
    companyGroupSchedule?: number
    language?: Language
    page?: number
    limit?: number
    flowType?: FlowType
    flowName?: string
    channel: string
    tab: ChannelKey
    timezone?: string
}

export type ChannelDraftDeleteTags = DeleteTagRequestFields

export type ChannelDraftTags = {
    tags?: Tags
    deleteTag?: ChannelDraftDeleteTags
}

export type ChannelDraftWorkingHours = {
    workingHours?: WorkingHours
    deleteWorkingHours?: DeleteWorkingHoursRequest
}

export type ChannelDraftExtras = {
    extras?: Extras
    deleteExtras?: DeleteExtraRequest
}

export type ChannelDraftMessages = {
    messages?: Messages[]
    deleteMessages?: DeleteMessages[]
}
export type ChannelDraftSelfSchedule = Configured

export type AuthUserInfo = { group: string; name: string }

export type ChannelDraftSaveRequest = {
    user: AuthUserInfo
    channel: string
    name?: string
    channelDraftTags?: ChannelDraftTags
    channelDraftExtras?: ChannelDraftExtras
    channelDraftGeneral?: ChannelDraftGeneral
    channelDraftMessages?: ChannelDraftMessages
    channelDraftSelfSchedule?: ChannelDraftSelfSchedule
    channelDraftMenus?: ChannelDraftMenus
    channelDraftSelfRescue?: SelfRescueConfigView
    channelDraftWorkingHours?: ChannelDraftWorkingHours
    channelDraftAiConfig?: ChannelAiConfigDraft
    channelDraftFlowBuilder?: ChannelDraftFlowBuilder
}
export type ChannelAiConfigDraftPayload = ChannelAiConfigDraft
export type ChannelDraftDiscardRequest = { user: AuthUserInfo; channel: string }
export type ChannelDraftDiscardResponse = ChannelsResponse

export type ChannelDraftPublishRequest = { user: AuthUserInfo; channel: string }
export type ChannelDraftPublishResponse = ChannelsResponse

export const checkSourceOrMedia = (item: Source | Media, configured: string) => {
    return item.id === configured || item.description === configured
}
export const handleSourceOrMedia = (item: GenericStatusType, entries: Media[] | Source[]) => {
    const response = item ?? { name: '', error: true }
    for (const entry of entries) {
        if (checkSourceOrMedia(entry, item?.name ?? response.name)) {
            response.name = entry.id
            break
        }
    }
    return response
}

export type ChannelAiConfigDraftRequest = {
    channel: string
}

export type ChannelAiConfigDraftRequestQueryParam = {
    group: string
    channel: string
    ignoreCache: string
}
